<script lang="ts">
  import CartIcon from '@svelte/components/atoms/icons/cart/Cart.svelte';
  import CartContents from '../../../../components/molecules/checkout/cart-summary-contents/CartContents.svelte';
  import FloatAction from '../actions/FloatAction.svelte';
  import { makeClassNames } from 'lib/util';
  import { contentClassNames } from '../styles';

  export let disabled: boolean;

  let closeAction: () => void;
</script>

<FloatAction actionId="cart" {disabled} bind:reset={closeAction} on:toggle>
  <CartIcon slot="closed" labelId="cart-popover" />
  <CartContents
    className={makeClassNames(contentClassNames, 'w-80')}
    slot="contents"
    on:navigation={closeAction}
  />
</FloatAction>
