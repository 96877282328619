<script lang="ts" context="module">
  const routesWithoutCartButton = [
    MAIN_NAVIGATION_ROUTER_KEY.CHECKOUT,
    MAIN_NAVIGATION_ROUTER_KEY.ACCOUNT
  ];
</script>

<script lang="ts">
  import { fade } from 'svelte/transition';
  import { currentRouteKeyStore } from 'state/stores/nav';
  import { MAIN_NAVIGATION_ROUTER_KEY } from 'lib/globalConfig';
  import { onDestroy, onMount } from 'svelte';
  import CartPopOver from './cart/PopOver.svelte';
  import ChatPopOver from './chat/PopOver.svelte';
  import NotificationsMenuItem from './notifications/NotificationsMenuItem.svelte';
  import { ToggleDetail } from './types';
  import { getAppEventDispatcher } from '@svelte/lib/events/context';
  import { AppEventName, CommsMenuDevice } from 'lib/events/contracts';
  import DesktopDialog from './dialog/DesktopDialog.svelte';

  export let appEventDispatcher = getAppEventDispatcher();
  const commsDevice: CommsMenuDevice = 'desktop';
  let visible = false;
  let prevActiveAction = '';
  let activeAction = '';

  $: currentRouteKey = $currentRouteKeyStore;
  $: showCart =
    !currentRouteKey || !routesWithoutCartButton.includes(currentRouteKey);

  onMount(() => {
    visible = true;
    appEventDispatcher.dispatch(AppEventName.CommsMenuMounted, {
      device: commsDevice
    });
  });

  onDestroy(() => {
    appEventDispatcher.dispatch(AppEventName.CommsMenuUnmounted, {
      device: commsDevice
    });
  });

  const handleToggle = ({ detail }: CustomEvent<ToggleDetail>) => {
    const { actionId } = detail;
    if (activeAction !== actionId && detail.open) {
      if (actionId === 'notifications') {
        prevActiveAction = activeAction;
      }
      activeAction = actionId;
    } else if (activeAction === actionId && !detail.open) {
      activeAction = prevActiveAction;
      prevActiveAction = '';
    }
  };
</script>

<DesktopDialog />
{#if visible}
  <ul
    transition:fade
    role="menu"
    class="fixed bottom-rhythm0 right-rhythm0 z-50 hidden lg:block"
  >
    <div class="relative">
      <slot name="notifications">
        <NotificationsMenuItem
          actionId="notifications"
          on:toggle={handleToggle}
        />
      </slot>
      {#if showCart}
        <slot name="cart">
          <CartPopOver
            disabled={!!activeAction && activeAction !== 'cart'}
            on:toggle={handleToggle}
          />
        </slot>
      {/if}
      <slot name="chat">
        <ChatPopOver
          disabled={!!activeAction && activeAction !== 'chat'}
          on:toggle={handleToggle}
        />
      </slot>
    </div>
  </ul>
{/if}
