<script lang="ts">
  import ActionButton from './button/ActionButton.svelte';
  import { fade } from 'svelte/transition';
  import { ActionButtonState } from './button/state';
  import { createEventDispatcher } from 'svelte';
  import { ToggleDetail } from '../types';
  import MenuContents from '../components/MenuContents.svelte';

  const dispatch = createEventDispatcher();

  export let actionId: string;
  export let loading = false;
  export let disabled = false;
  export const reset = () => {
    interactingWithContents = false;
    closedByUser = false;
  };

  let interactingWithContents = false;
  let closedByUser = false;
  $: isOpen = interactingWithContents && !closedByUser;
  let buttonState: ActionButtonState = ActionButtonState.Closed;
  let enterDelay: NodeJS.Timeout | undefined;
  let leaveDelay: NodeJS.Timeout | undefined;

  $: {
    if (isOpen && !disabled) {
      buttonState = loading
        ? ActionButtonState.Loading
        : ActionButtonState.Opened;
      dispatch('toggle', {
        actionId,
        loading,
        open: true
      } as ToggleDetail);
    } else {
      buttonState = ActionButtonState.Closed;
      dispatch('toggle', {
        actionId,
        loading: false,
        open: false
      } as ToggleDetail);
    }
  }
</script>

<li
  transition:fade
  role="menuitem"
  aria-expanded={isOpen}
  aria-haspopup="true"
  class="mb-rhythm0"
  on:mouseenter={() => {
    clearTimeout(leaveDelay);
    enterDelay = setTimeout(() => {
      if (!disabled) {
        interactingWithContents = true;
      }
    }, 200);
  }}
  on:mouseleave={() => {
    clearTimeout(enterDelay);
    leaveDelay = setTimeout(() => {
      closedByUser && reset();
    }, 200);
  }}
>
  <ActionButton
    on:toggle={() => {
      interactingWithContents = true;
      closedByUser = isOpen;
    }}
    {disabled}
    state={buttonState}
  >
    <slot name="closed" />
  </ActionButton>

  {#if isOpen && !disabled}
    <MenuContents>
      <slot name="contents" />
    </MenuContents>
  {/if}
</li>
